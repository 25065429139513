import * as React from 'react';
import Helmet from 'react-helmet';
import { withPrefix, graphql, Link } from 'gatsby';

import Intro from 'components/intro/Intro';
import { Highlight } from 'components/intro/Highlight';


export default () => (
  <>
    <Helmet title="Home">
      <script src={withPrefix('intro.js')} type="text/javascript" />
    </Helmet>

    <Intro />

  </>
);
