import React, { PureComponent } from 'react';
import * as THREE from 'three';
import { Link } from 'gatsby';
import { TimelineLite } from 'gsap';
import waitForGlobal from 'utils/waitForGlobal';

import s from './Intro.scss';

interface IProps {
  children: React.ReactNode;
}

export default class Intro extends PureComponent<IProps> {

  refCanvas = React.createRef<HTMLDivElement>();
  refWrapper = React.createRef<HTMLDivElement>();
  refScene = React.createRef<HTMLDivElement>();

  componentDidMount() {
    // check if Tito is available
    waitForGlobal('IntroSphere').then(() => this.setupIntro());
  }

  setupIntro = () => {
    IntroSphere.init();

    const t = new TimelineLite();

    t.addLabel('start');

    if (this.refScene.current) {
      t.fromTo(
        this.refScene.current,
        1.2,
        { autoAlpha: 0, y: '20px' },
        { autoAlpha: 1, y: '0px', ease: 'Power4.easeInOut' },
        'start',
      );
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div className={s.intro}>
        <div className={s.intro__content}>
          <canvas className={s.intro__scene} ref={this.refScene} id="scene" />
          <div className={s.intro__inner}>
            <h2 className={s.intro__heading}>Zoldánar</h2>
            <h2 className={s.intro__subheading}>Sveiflunnar</h2>
          </div>
        </div>

        <footer className={s.intro__navigation} id="navigation">
          <div className={s.intro__navigationLeft}>
            <ul className={s.intro__list}>
  				    <li className={s.intro__listItem}><Link className={s.intro__navigationItem} data-color="#ff0000" to="/2019">Best Albums of 2019</Link></li>
              <li className={s.intro__listItem}><Link className={s.intro__navigationItem} data-color="#00ff00" to="/2019-iceland">Best Icelandic Albums of 2019</Link></li>
              <li className={s.intro__listItem}><Link className={s.intro__navigationItem} data-color="#00ffff" to="/about">About</Link></li>
            </ul>
          </div>

  			</footer>
      </div>
    );
  }
}
